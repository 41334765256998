(function () {
  'use strict';

  class EditTeachersCtrl {
    constructor($mdDialog, EditTeachers, User, Schools) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.User = User;
      vm.Schools = Schools;
      vm.user = EditTeachers.getObj();
      vm.init();
    }
    init() {
      const vm = this;
      vm.getSchools();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    getSchools() {
      const vm = this;
      vm.Schools.schoolsIndex()
        .then((data)=> {
          vm.schoolsIndex = data;
        });
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.User.editTeacher(vm.user)
          .then((data)=> {
            vm.hide(data);
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:EditTeachersCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('EditTeachersCtrl', EditTeachersCtrl);
}());
